import '../index.css';

import { graphql, useStaticQuery } from 'gatsby';

import Header from './Header';
import Helmet from 'react-helmet';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Layout = ({ children }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
          facebookURL
          description
          keywords
        }
      }
    }
  `);

  return (
    <div>
      <Helmet
        title={siteMetadata.title}
        meta={[
          {
            name: 'description',
            content: siteMetadata.description,
          },
          {
            name: 'keywords',
            content: siteMetadata.keywords,
          },
        ]}
      >
        <script src="https://cdn.polyfill.io/v2/polyfill.min.js" />
      </Helmet>
      <Header title={siteMetadata.title} />
      <div
        css={{
          margin: '0 auto',
          maxWidth: 960,
          padding: '20px',
          '@media(max-width: 800px)': {
            padding: 30,
          },
        }}
      >
        {children}
      </div>

      <div css={{ textAlign: 'center', padding: 20 }}>
        <div>
          Følg med på vår <a href={siteMetadata.facebookURL}>Facebook-side</a>{' '}
          for jevnlige oppdateringer.
        </div>
        <div css={{ paddingTop: 10 }}>
          <StaticImage src="../300se.jpg" alt="Logo" />
        </div>
      </div>
    </div>
  );
};

export default Layout;
