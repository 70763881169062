import { Link } from 'gatsby';
import React from 'react';

const activeStyle = {
  background: '#ddd',
};

const linkStyle = {
  color: '#3E4146',
  padding: '5px 10px',
  borderRadius: 4,
};

const Header = ({ title }) => (
  <div
    css={{
      background: '#F4F3F4',
      borderBottom: '5px solid #eee',
      boxShadow: 'inset 0 -5px 0px 0px #ddd',
    }}
  >
    <div
      css={{
        margin: '0 auto',
        maxWidth: 960,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px',
        '@media(max-width: 800px)': {
          display: 'block',
          textAlign: 'center',
        },
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          css={{
            color: '#C02122',
            textDecoration: 'none',
            ':hover': {
              color: '#C02122',
            },
          }}
        >
          {title}
        </Link>
      </h1>

      <div>
        <Link css={linkStyle} activeStyle={activeStyle} to="/">
          Hovedside
        </Link>
        <Link css={linkStyle} activeStyle={activeStyle} to="/om">
          Om bedriften
        </Link>
        {/* <Link
          css={linkStyle}
          activeStyle={activeStyle}
          to="/deler"
          partiallyActive
        >
          Til salgs
        </Link> */}
      </div>
    </div>
  </div>
);

export default Header;
